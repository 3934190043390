import { convertCustomObjects, Timestamp } from "@/db";
import store from "../store";
import Contact from "./contact";
import Customer from "./customer";
import EDepartments from "@core-shared/enums/Departments";
import EJobStages from "@core-shared/enums/JobStages";
import EProjectGroups from "@core-shared/enums/ProjectGroups";
import ICustomerIdentifier from "@core-shared/interfaces/ICustomerIdentifier";
import IJob from "@core-shared/interfaces/IJob";
import IJobContacts from "@core-shared/interfaces/IJobContacts";
import ProcoreUser from "./procoreUser";
import SecondaryStatus from "./secondaryStatus";
import Site from "./site";
import User from "./user";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export interface ISurveyFile {
	filename: string;
	cloudStorageLink: string;
	surveyQuestionTitle: string;
}

export default class Job implements IJob {
	["id"]!: string;

	address: string = "";
	customerID: string = "";
	customerName: string = "";
	customer!: Customer;

	site!: Site;

	department!: EDepartments;
	projectGroup!: EProjectGroups;

	description: string = "";
	name: string = "";
	number: string = "";
	toInvoice: boolean = false;
	invoiced: boolean = false;
	createdBy: User = store.state.storeUser;
	stage!: EJobStages;
	created: firebase.firestore.Timestamp = Timestamp.now();
	procoreId?: string;
	startDate: firebase.firestore.Timestamp = Timestamp.now();
	completionDate: firebase.firestore.Timestamp = Timestamp.now();
	totalValue: number = 0;
	procoreURL: string = "";
	googleDriveURL: string = "";
	customerIdentifiers: ICustomerIdentifier[] = [
		{ name: "PO", value: "" },
		{ name: "WO", value: "" },
		{ name: "UCC", value: "" },
		{ name: "UC", value: "" },
		{ name: "Order No", value: "" },
	];
	contacts: IJobContacts[] = [];

	//Project Management
	projectManager?: User = undefined;
	estimator?: User = undefined;
	supervisor?: User = undefined;
	sitelead?: ProcoreUser = undefined;

	secondaryStatus: SecondaryStatus = new SecondaryStatus();
	surveyData: string = "";
	surveyTemplate: string = "";
	surveyFiles: ISurveyFile[] = []

	projectAdminSurveyData: string = ""
	projectAdminSurveyTemplate: string = "";
	projectAdminSurveyFiles: ISurveyFile[] = []

	constructionManagerSurveyData: string = ""
	constructionManagerSurveyTemplate: string = "";
	constructionManagerSurveyFiles: ISurveyFile[] = []

	constructor(stage = "", partial?: Partial<Job>) {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});

		// this.stage = stage;
		Object.assign(this, partial);
	}
	primaryContact!: Contact;
	billingContact!: Contact;


	static FirestoreDataConverter = {
		toFirestore(job: Job): firebase.firestore.DocumentData {
			//Enforce business rules and type rules here
			return convertCustomObjects(job);
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): Job {
			const data = snapshot.data(options) ?? {};
			const retjob = Object.assign(new Job(), data);
			return retjob;
		}
	}

	static TitleFormatter(job: Job | undefined): string {
		if (!job) {
			return "";
		}
		const customeridentifiers: string[] = [];
		const title = [];
		const ret = [];

		job.customerIdentifiers.forEach(identifier => {
			if (identifier.value) {
				customeridentifiers.push(`${identifier.name} ${identifier.value}`);
			}
		});

		if (job.number) {
			title.push(job.number)
			title.push(" - ")
		}
		if (job.customer?.shortName || job.customer?.name || job.site?.name) {
			title.push("[")
			if (job.customer?.shortName) {
				title.push(job.customer.shortName);
				title.push(" » ")
			} else if (job.customer?.name) {
				title.push(job.customer.name);
				title.push(" » ")
			}
			if (job.site?.name) {
				title.push(job.site.name)
			}
			title.push("] ")
		}

		if (job.name) {
			title.push(job.name)
		}

		ret.push(title.join(""))
		if (customeridentifiers.length) {
			ret.push(customeridentifiers.join(" ❖ "))
		}
		return ret.join(" ⭆ ");
	}

	static GetRandomPostFix(length: number = 3): string {
		const randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		let result = "";
		for (let i = 0; i < length; i++) {
			result += randomChars.charAt(
				Math.floor(Math.random() * randomChars.length)
			);
		}
		return result;
	}
}
